import { ActiveHomeFeedProvider } from "@/contexts/ActiveHomeFeedContext";
import { AuthPromptProvider } from "@/contexts/AuthPromptContext";
import { useAuth } from "@/hooks/auth";
import { useQueryChatsWithUnreadMessages } from "@/hooks/chat";
import { useGetNotifications } from "@/hooks/notification";
import { useGetUser } from "@/hooks/user";
import { dontShowSidebar } from "@/utils/layout";
import { useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import Icon from "../icon/Icon";
import ProfilePicture from "../profile/ProfilePicture";
import Navbar from "./Navbar";

export default function RootLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div className={`relative h-screen font-montserrat antialiased font-normal font-sm bg-neutral`}>
      <main className="relative flex flex-col h-screen">
        <Navbar sidebarOpen={sidebarOpen} toggleSidebar={setSidebarOpen} />

        <div className="flex-1 bg-neutral no-scrollbar overflow-y-hidden overflow-x-hidden h-[calc(100%-4rem)]">
          <div className="relative flex flex-1 h-full">
            <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={setSidebarOpen} />
            <Content />
          </div>
        </div>
      </main>
    </div>
  );
}

interface SidebarProps {
  sidebarOpen: boolean;
  toggleSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}

const Sidebar = ({ sidebarOpen, toggleSidebar }: SidebarProps) => {
  const { getUserId, isAuthenticated } = useAuth();
  const userId = getUserId() as string;
  const { data: user, isLoading: isUserLoading } = useGetUser(userId);
  const location = useLocation();

  const { data: unreadChatsData } = useQueryChatsWithUnreadMessages({ limit: 99 });
  const unreadChats = unreadChatsData?.pages.flatMap((page) => page.items) || [];
  const unreadMessagesCount = unreadChats.length;

  const { data: notificationsData } = useGetNotifications(userId, { limit: 99 });
  const notifications = notificationsData?.pages.flatMap((page) => page.items) || [];
  const unreadNotificationsCount = notifications.filter((n) => n.status === "UNREAD").length;

  if (dontShowSidebar.includes(location.pathname)) {
    return null;
  }

  if (isUserLoading || !user || !isAuthenticated) {
    return null;
  }

  return (
    <>
      {/* Desktop Sidebar */}
      <div className="hidden lg:block border-r border-gray-300 z-50">
        <div className="w-72 p-4 h-full relative">
          <div className="space-y-1 sm:space-y-6">
            <Link to={`/${user.username}`} className="flex justify-center items-center mt-4">
              <ProfilePicture src={user.profilePic?.[400]} user={user} styles="w-24 h-24 mr-3" avatarSize="text-2xl" />
              <div className="flex flex-col">
                <div className="flex items-center">
                  <span className="font-bold text-textPrimary text-xl">{user.displayName ?? user.username}</span>
                </div>
                <span className="text-textSecondary text-base">@{user.username}</span>
              </div>
            </Link>

            <nav className="nav space-y-1 w-full h-full">
              <SidebarLink href="/" icon="home" label="Home" location={location} />
              <SidebarLink
                href="/messages"
                icon="chat_bubble"
                label="Messages"
                badge={unreadMessagesCount}
                location={location}
              />
              <SidebarLink
                href="/notifications"
                icon="notifications"
                label="Notifications"
                badge={unreadNotificationsCount}
                location={location}
              />
              <SidebarLink
                href="/edit-profile#monetization"
                icon="attach_money"
                label="Monetization"
                location={location}
              />
              <SidebarLink href="/orders" icon="orders" label="Orders" location={location} />
              <SidebarLink href={`/${user.username}`} icon="person" label="Profile" location={location} />
            </nav>
          </div>
        </div>
      </div>

      {/* Mobile Sidebar Drawer */}
      <div
        className={`absolute top-0 left-0 z-50 w-72 h-full bg-neutral border-r border-gray-300 transform transition-transform ${
          sidebarOpen ? "translate-x-0" : "-translate-x-full"
        } lg:hidden`}
      >
        <button className="absolute top-4 right-4 btn btn-ghost btn-circle" onClick={() => toggleSidebar(!sidebarOpen)}>
          <Icon name="close" size={24} />
        </button>
        <div className="p-4 h-full space-y-2 sm:space-y-6">
          <Link to={`/${user.username}`} className="flex justify-center items-center mt-8 my-4">
            <ProfilePicture src={user.profilePic?.[400]} user={user} styles="w-24 h-24 mr-3" avatarSize="text-2xl" />
            <div className="flex flex-col">
              <div className="flex items-center">
                <span className="font-bold text-textPrimary text-xl">{user.displayName ?? user.username}</span>
              </div>
              <span className="text-textSecondary text-base">@{user.username}</span>
            </div>
          </Link>

          <nav className="nav space-y-2 w-full h-full">
            <SidebarLink href="/" icon="home" label="Home" location={location} />
            <SidebarLink
              href="/messages"
              icon="chat_bubble"
              label="Messages"
              badge={unreadMessagesCount}
              location={location}
            />
            <SidebarLink
              href="/notifications"
              icon="notifications"
              label="Notifications"
              badge={unreadNotificationsCount}
              location={location}
            />
            <SidebarLink
              href="/edit-profile#monetization"
              icon="attach_money"
              label="Monetization"
              location={location}
            />
            <SidebarLink href="/orders" icon="orders" label="Orders" location={location} />
            <SidebarLink href={`/${user.username}`} icon="person" label="Profile" location={location} />
          </nav>
        </div>
      </div>

      {/* Backdrop for Drawer */}
      {sidebarOpen && (
        <div
          className="fixed inset-0 bg-primary bg-opacity-50 z-40 lg:hidden"
          onClick={() => toggleSidebar(!sidebarOpen)}
        />
      )}
    </>
  );
};

const SidebarLink = ({
  href,
  icon,
  label,
  badge,
  location,
}: {
  href: string;
  icon: string;
  label: string;
  badge?: number;
  location: { pathname: string };
}) => {
  const isActive = location.pathname === href;

  return (
    <a
      href={href}
      className={`btn btn-block btn-ghost cursor-pointer btn-sm h-12 sm:h-14 ${isActive ? "bg-primary" : ""} flex items-center justify-between rounded-2xl`}
    >
      <div className="flex items-center space-x-3 text-base font-semibold tracking-wide font-montserrat">
        <div className="indicator">
          <Icon name={icon} size={24} color={isActive ? "#F8F9FA" : "#212529"} className="pb-1" />
          {badge && badge > 0 ? (
            <span className="indicator-item badge badge-error text-base-100 w-5 h-5 min-h-0 p-1 min-w-0 text-xs">
              {badge > 99 ? "99+" : badge}
            </span>
          ) : null}
        </div>
        <span className={`${isActive ? "text-base-100" : "text-textPrimary"}`}>{label}</span>
      </div>
    </a>
  );
};

const Content = () => {
  return (
    <div className="flex-1 bg-base-100/30 w-full h-full overflow-y-scroll no-scrollbar rounded-b-xl">
      <AuthPromptProvider>
        <ActiveHomeFeedProvider>
          <Outlet />
        </ActiveHomeFeedProvider>
      </AuthPromptProvider>
    </div>
  );
};
