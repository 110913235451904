import { Content, Notification } from "@yoga-app/types";

/**
 * Returns the link to the content page based on the content type.
 */
export const getContentPageLink = (username: string, contentId: string, type: Content) => {
  return `/${username}/${type.toLowerCase()}/${contentId}`;
};

/**
 * Returns a url based on the notification item type.
 */
export const getNotificationUrl = (notification: Notification): string | null => {
  const { itemUri, toUsername, fromUsername, fromUserId } = notification;

  switch (itemUri.type) {
    case "POST":
    case "PACKAGE":
    case "SESSION":
      return getContentPageLink(toUsername, itemUri.id, itemUri.type);
    case "SESSION_EVENT":
      return `/order/${itemUri.id}/${fromUserId}`;
    case "USER":
      return `/${fromUsername}`;

    default:
      return null;
  }
};
