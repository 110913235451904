import ActionButton from "@/components/button/ActionButton";
import { useApproveReportedContent, useGetAllReportedContent } from "@/hooks/content";
import PreviewCard from "@/src/components/content/dashboard/PreviewCard";
import Table from "@/src/components/list/Table";
import { formatHumanReadableDate } from "@/utils/date";
import { Content, Package, Post, Session } from "@yoga-app/types";
import React, { useState } from "react";

interface ReportedContentTable {
  id: string;
  type: string;
  createdAt: string;
  reason: string;
  actions: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
  rawContent: Post | Session | Package;
}

const ReportedContent: React.FC = () => {
  const [pageLimit, setPageLimit] = useState(10);
  const { mutate: approveContent } = useApproveReportedContent();

  const {
    data,
    isLoading,
    error,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isFetchingPreviousPage,
    hasPreviousPage,
    fetchPreviousPage,
  } = useGetAllReportedContent({ limit: pageLimit, cursor: undefined });

  const handleApprove = (contentId: string, contentType: Content, createdAt: string, userId: string) => {
    const contentItemUri = { type: contentType, id: contentId };
    approveContent({ contentItemUri, contentCreatedAt: createdAt, contentUserId: userId });
  };
  const handleDisapprove = (contentId: string) => {
    console.log("Disapproved content:", contentId);
  };

  const contentData: ReportedContentTable[] =
    data?.pages.flatMap((page) =>
      page.items.map(({ content }) => ({
        id: content.id,
        type: content.type,
        createdAt: formatHumanReadableDate(content.createdAt),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
        reason: (content as any).reportReason,
        actions: (
          <div className="flex space-x-2">
            <ActionButton
              text="Approve"
              onClick={() => handleApprove(content.id, content.type, content.createdAt, content.userId)}
              className="btn btn-success btn-sm text-base-100"
            />
            <ActionButton
              text="Disapprove"
              onClick={() => handleDisapprove(content.id)}
              className="btn btn-error btn-sm text-base-100"
            />
          </div>
        ),
        rawContent: content,
      })),
    ) || [];

  const columns: { header: string; accessor: keyof ReportedContentTable }[] = [
    { header: "Type", accessor: "type" },
    { header: "Created At", accessor: "createdAt" },
    { header: "Reason", accessor: "reason" },
    { header: "Actions", accessor: "actions" },
  ];

  if (isLoading && !isFetchingNextPage) {
    return (
      <div className="flex justify-center items-center h-full">
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-full">
        <p className="text-error">Failed to load reported content.</p>
      </div>
    );
  }

  return (
    <div>
      <h1 className="text-3xl font-bold mb-4">Reported Content</h1>
      <Table<ReportedContentTable>
        columns={columns}
        data={contentData}
        pageLimit={pageLimit}
        pageLimitOptions={[10, 20, 50]}
        onPageChange={(newLimit) => setPageLimit(newLimit)}
        onNextPage={fetchNextPage}
        onPreviousPage={fetchPreviousPage}
        hasNextPage={!!hasNextPage}
        hasPreviousPage={!!hasPreviousPage}
        isFetchingNextPage={isFetchingNextPage}
        isFetchingPreviousPage={isFetchingPreviousPage}
        expandedRowComponent={(row) => (
          <div className="flex justify-center items-center p-4 w-full">
            <PreviewCard content={row.rawContent} />
          </div>
        )}
      />
    </div>
  );
};

export default ReportedContent;
