import Icon from "@/components/icon/Icon";
import { getContentIcon } from "@/utils/icon";
import { Content } from "@yoga-app/types";

interface ContentBadgeProps {
  type: Content;
  className?: string;
  iconSize?: number;
}

const formatContentType = (type: Content) => {
  const contentTypes = {
    POST: "Post",
    SESSION: "Session",
    PACKAGE: "Package",
  };
  return contentTypes[type] || "Content";
};

const ContentBadge = ({ type, className = "", iconSize = 22 }: ContentBadgeProps) => {
  return (
    <div className={`badge flex items-center p-2 ${className}`}>
      <Icon name={getContentIcon(type)} color="#32325d" size={iconSize} className="mr-1" />
      <span>{formatContentType(type)}</span>
    </div>
  );
};

export default ContentBadge;
