import MaxWidthWrapper from "../layout/MaxWidthWrapper";

const GlobalErrorBoundary = () => {
  return (
    <MaxWidthWrapper className="flex items-center justify-center h-full">
      <div className="text-center">
        <h1 className="text-3xl font-bold mb-4">Something went wrong</h1>
        <p className="text-lg">We're sorry, but an unexpected error occurred.</p>
      </div>
    </MaxWidthWrapper>
  );
};

export default GlobalErrorBoundary;
