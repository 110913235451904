import { z } from "zod";

const notificationTypeSchema = z.enum([
  "LIKE",
  "FOLLOW",
  "COMMENT",
  "PAYMENT",
  "FREE_SESSION",
  "MENTION_POST",
  "MENTION_COMMENT",
  "REFUND_PROCESSED",
]);
export type NotificationType = z.infer<typeof notificationTypeSchema>;

export const notificationStatusSchema = z.enum(["READ", "UNREAD"]);
export type NotificationStatus = z.infer<typeof notificationStatusSchema>;

const notificationItemTypeSchema = z.enum(["POST", "PACKAGE", "SESSION", "USER", "SESSION_EVENT"]);
export type NotificationItemType = z.infer<typeof notificationItemTypeSchema>;

const notificationItemUriSchema = z.object({
  /**
   * The id of the items that the notification is about.
   * - Post id
   * - Comment id
   * - User id
   * - SessionEvent id
   */
  id: z.string(),
  /**
   * The type of item the notification is about.
   *
   * e.g., post, user, package, session
   */
  type: notificationItemTypeSchema,
});
export type NotificationItemUri = z.infer<typeof notificationItemUriSchema>;

export const notificationSchema = z.object({
  /**
   * The id of the notification.
   */
  id: z.string(),
  /**
   * That notification's status. determines if the user has read the notification.
   */
  status: notificationStatusSchema,

  /**
   * The type of the notification the action is about.
   *
   * - LIKE: A user liked a content item (e.g., post, comment)
   * - FOLLOW: A user followed another user
   * ect.,
   */
  type: notificationTypeSchema,

  /**
   * The user id of the user who the notification is from e.g., the fromUserId just liked the toUserId's post.
   */
  fromUserId: z.string(),
  fromUsername: z.string(),

  /**
   * The user id of the user who the notification is for e.g., the fromUserId just liked the toUserId's post.
   */
  toUserId: z.string(),
  toUsername: z.string(),

  /**
   * The item of the notification.
   */
  itemUri: notificationItemUriSchema,

  /**
   * The message of the notification. e.g., "John liked your post."
   */
  message: z.string(),

  /**
   * The date and time the notification was created.
   *
   * The date is represented as an ISO 8601 date string.
   */
  createdAt: z.string().datetime({ offset: true }),
  /**
   * The date and time the notification was last updated.
   *
   * The date is represented as an ISO 8601 date string.
   */
  updatedAt: z.string().datetime({ offset: true }),
});
export type Notification = z.infer<typeof notificationSchema>;
