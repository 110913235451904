import { Link } from "react-router-dom";

const TermsOfService = () => {
  return (
    <div className="container mx-auto max-w-4xl p-6">
      <h1 className="text-4xl font-bold mb-6 text-center">Terms of Service</h1>
      <h2 className="text-xl font-semibold">Terms of Service (“Terms”)</h2>

      <div className="space-y-6 text-lg text-gray-700">
        {/* Terms introduction */}
        <section>
          <p>
            These Terms of Service (“Terms”) govern your and other users’ access to and use of bodhiga’s services,
            including our websites, applications, APIs, notifications, and any other related features or offerings
            (collectively, the “Services”). The Services encompass all interactions with information, text, links,
            graphics, photos, audio, videos, or other materials that appear on the platform (“Content”). By accessing or
            using the Services, you agree to be bound by these Terms.
          </p>
          <p>
            These Terms constitute an agreement between you and bodhiga. References to “we,” “us,” and “our” refer to
            bodhiga.
          </p>
          <p>
            Please note that these Terms incorporate our{" "}
            <Link to={`/privacy-policy`} className="text-blue-600 dark:text-blue-500 hover:underline">
              Privacy Policy
            </Link>
          </p>
        </section>
        <div className="divider divider-horizontal"></div>
        {/* Eligibility to Use the Services */}
        <section>
          <h2 className="text-xl font-semibold">Eligibility to Use the Services</h2>
          <p>
            You may access and use the Services only if you can form a legally binding contract with bodhiga and are not
            prohibited from using the Services under any applicable law. You must be at least 13 years old to use the
            Services.By either (i) agreeing to these Terms for your personal use or (ii) accepting these Terms to
            authorize use of the Services on behalf of a minor (defined as an individual under the age of legal majority
            in your jurisdiction), organization, business, government, or other legal entity, you confirm that you have
            the authority to do so. In these Terms, “you” and “your” refer to either you as an individual or, as
            applicable, the minor or entity you represent.
          </p>
        </section>
        <div className="divider divider-horizontal"></div>
        {/* Privacy */}
        <section>
          <h2 className="text-xl font-semibold">Privacy</h2>
          <p>
            Our Privacy Policy outlines how we collect, use, and manage the information you provide when using our
            Services. By accessing or using the Services, you acknowledge and agree to the data practices described in
            our Privacy Policy.
          </p>
        </section>
        <div className="divider divider-horizontal"></div>
        {/* Content on the Services */}
        <section>
          <h2 className="text-xl font-semibold">Content on the Services</h2>
          <p>
            You are responsible for your use of the Services and any Content you share, ensuring it complies with
            relevant laws and regulations. Only post Content that you are comfortable sharing publicly.
          </p>
          <p>
            Any use or reliance on Content available through the Services is at your own risk. bodhiga does not endorse,
            support, or guarantee the accuracy, truthfulness, or reliability of any Content or statements made via the
            Services. By using the Services, you may encounter Content that could be offensive, misleading, incorrect or
            harmful. Content suggestions are based on factors like your activity on the Services, topics of interest,
            and interactions with other users who have similar preferences. All Content remains the sole responsibility
            of the person who shared/originated it. We may not monitor all Content or control all the Content shared on
            the Services and cannot be held accountable for it.
          </p>
          <p>
            We reserve the right to remove Content that violates these Terms, including but not limited to, cases of
            intellectual property infringement, impersonation, unlawful conduct, or harassment.
          </p>
          <p>
            If you believe your Content has been used without permission in a way that violates copyright, please reach
            out to <span className="text-blue-600 dark:text-blue-500 hover:underline">support@bodhiga.com</span>.
          </p>
        </section>
        <div className="divider divider-horizontal"></div>
        {/* Sessions */}
        <section>
          <h2 className="text-xl font-semibold">Sessions</h2>
          <p>
            bodhiga allows users to create and participate in “Sessions,” which are structured classes, trainings, or
            meetings offered by users on the platform. Sessions may take place online or in-person, depending on the
            Content originators preferences.
          </p>
          <p>
            Users offering Sessions and its participants are solely responsible for ensuring that the Session is
            conducted in a safe, lawful, and respectful manner.bodhiga does not verify the qualifications or credentials
            of users Sessions, and participation is at the participant’s own risk.
          </p>
          <p>
            bodhiga does not oversee or control in-person or online interactions. Accordingly,bodhiga is not liable for
            any injury, loss or dispute arising from in-person Sessions. We recommend all parties take appropriate
            precautions when meeting in person.
          </p>
          <p>
            Scheduling, rescheduling, and cancellation policies for Sessions are determined by the originator of the
            Content. Participants should contact the originator/creator directly for any scheduling issues. bodhiga is
            not responsible for enforcing or facilitating refunds for cancellations unless stated otherwise.
          </p>
          <p>
            bodhiga recommends that participants consult a healthcare provider before starting any fitness session,
            particularly if they have any medical condition that may affect their participation.bodhigadoes not provide
            medical advice, diagnosis, or treatment.
          </p>
          <p>
            By participating in fitness Sessions, users acknowledge that physical activities involve inherent risks,
            including injury, physical strain, or health complications. Participation is voluntary, and users assume
            full responsibility for any risk associated with these activities.
          </p>
        </section>
        <div className="divider divider-horizontal"></div>
        {/* Your Rights and License to Your Content */}
        <section>
          <h2 className="text-xl font-semibold">Your Rights and License to Your Content</h2>
          <p>
            You retain ownership of any Content you submit, post, or display on or through bodhiga’s Services. What
            belongs to you remains yours, including any audio, photos, or videos that you incorporate into your Content.
          </p>
          <p>
            By submitting, posting, or displaying Content on or through the Services, you grant bodhiga a worldwide,
            non-exclusive, royalty-free license (with the right to sublicense) to use, copy, modify, adapt, publish,
            display, distribute, and process your Content across any media or distribution methods that exist now or may
            be developed in the future. This includes rights for actions such as organizing, translating, and adapting
            Content. This license allows us to make your Content accessible to others on a global scale and to let
            others do the same. However, if you choose to restrict your Content’s visibility to a select community, we
            will honor that choice.
          </p>
          <p>
            Additionally, this license allows us to analyze Content to help enhance our Services. You acknowledge that
            this license enables bodhiga to (i) operate, promote, and refine our Services and (ii) share your Content
            with other companies, organizations, or individuals for the purposes of distribution, syndication,
            broadcasting, and promoting your Content across various platforms, all in accordance with our Content usage
            terms. You agree that the use of bodhiga’s Services is considered sufficient compensation for the license
            granted here, and no additional payment will be made to you for your Content.
          </p>
          <p>
            We maintain a set of evolving guidelines to manage how partners within our ecosystem may interact with your
            Content, ensuring that your rights are protected. You understand that we may need to adapt or modify your
            Content as it is distributed, syndicated, or published, including adjustments necessary for display across
            various media formats.
          </p>
          <p>
            You represent and confirm that you have all necessary rights, licenses, permissions, or authority to share
            any Content that you submit, post, or display on or through the Services, including the authority to grant
            bodhiga the license described here. You agree that your Content will not contain any copyrighted material or
            other protected elements unless you have obtained the appropriate permissions or are otherwise legally
            authorized to share the material.
          </p>
        </section>
        <div className="divider divider-horizontal"></div>
        {/* Using the Services */}
        <section>
          <h2 className="text-xl font-semibold">Using the Services</h2>
          <p>
            Please review our Rules and Policies, which form part of these Terms and outline prohibited conduct on
            bodhiga. You agree to use the Services in accordance with these Terms and all relevant laws, regulations,
            and standards. bodhiga may take action if Content or behavior violates our Rules and Policies or involves
            sensitive material. If you believe an enforcement action was taken in error, you can appeal by contacting us
            at <span className="text-blue-600 dark:text-blue-500 hover:underline">support@bodhiga.com</span>.
          </p>
          <p>
            The Services are continuously evolving, and we may modify them at any time at our discretion. We may
            discontinue, temporarily or permanently, any aspect of the Services or restrict certain features for you or
            other users. bodhiga reserves the right to impose limitations on usage, storage, and access to the Services.
            We may remove or restrict the distribution or visibility of any Content, suspend or terminate accounts, or
            reclaim usernames when appropriate. Reasons for these actions include, but are not limited to: (i)
            safeguarding our Services or users, (ii) complying with laws or legal orders, (iii) violations of these
            Terms, our Rules and Policies, or third-party rights, (iv) mitigating legal or regulatory risk to us, our
            users, or third parties, and (v) prolonged account inactivity.
          </p>
          <p>
            By using the Services, you agree that we and our third-party partners may display advertisements on the
            platform or alongside Content, whether it is submitted by you or other users. We may also access, retain,
            and disclose any information as we deem necessary to (i) comply with applicable law or legal requests, (ii)
            enforce these Terms, including investigating possible violations, (iii) detect and prevent fraud, security
            breaches, or technical issues, (iv) respond to user support requests, and (v) protect the rights, property,
            or safety of bodhiga, our users, and the public. Any sharing of personally identifiable information with
            third parties is subject to our Privacy Policy.
          </p>
        </section>
        <div className="divider divider-horizontal"></div>
        {/* Your Account */}
        <section>
          <h2 className="text-xl font-semibold">Your Account</h2>
          <p>
            To access certain features of the Services, you may need to create an account. It is your responsibility to
            protect your account by using a secure password and restricting its use solely to this account. bodhiga is
            not liable for any loss or damage resulting from your failure to maintain the security of your account.
          </p>
          <p>
            We may send you important communications, including service announcements and administrative messages. These
            communications are an integral part of the Services and your account, and opting out may not be possible.
          </p>
        </section>
        <div className="divider divider-horizontal"></div>
        {/* Your licence to use the services */}
        <section>
          <h2 className="text-xl font-semibold">Your License to Use the Services</h2>
          <p>
            We grant you a personal, worldwide, royalty-free, non-transferable, and non-exclusive license to use the
            software provided as part of the Services. This license is strictly for your own use of bodhiga as allowed
            by these Terms and cannot be assigned, shared, sold, or transferred in any way without bodhiga’s written
            consent.
          </p>
          <p>
            The Services are protected by copyright, trademark, and other intellectual property laws. These Terms do not
            grant you any rights to use bodhiga’s name, trademarks, logos, domain names, or any distinctive brand
            features. All rights, titles, and interests in the Services (except for Content provided by users) are
            exclusively owned by bodhiga and its licensors. Any feedback, comments, or suggestions you provide about
            bodhiga or the Services are entirely voluntary, and we may use them at our discretion without any obligation
            to you.
          </p>
        </section>

        {/* Misuse of the Services */}
        <section>
          <h2 className="text-xl font-semibold">Misuse of the Services</h2>
          <p>
            You agree not to misuse the Services. Misuse includes actions like interfering with the Services or
            accessing them by methods outside of the approved interface and instructions we provide. You also agree not
            to bypass any technical restrictions or to reverse-engineer, decompile, or disassemble any software used as
            part of the Services, except where explicitly permitted by law.
          </p>
          <p>
            When using the Services, you may not: (i) access, tamper with, or use non-public areas of the Services,
            bodhiga’s systems, or the infrastructure of our providers; (ii) test or probe the vulnerability of any
            network or circumvent any security or authentication measures; (iii) attempt to access, search, or scrape
            the Services by any means (automated or otherwise) other than through our published, approved interfaces,
            and only under applicable terms (note: any crawling or scraping of the Services without prior written
            permission is strictly prohibited); (iv) falsify any TCP/IP packet header or any header information in any
            email or post; (v) use the Services to transmit misleading, deceptive, or false information regarding its
            source; (vi) violate our Rules and Policies, including misuse of our reporting features; or (vii) interfere
            with or disrupt the Services, including by sending viruses, overloading, spamming, mail-bombing, or using
            scripts to create excessive Content that burdens the Services.
          </p>
          <p>
            Additionally, it is a violation of these Terms to aid others in breaking these rules, including distributing
            products or services that enable or encourage such behavior.
          </p>
        </section>

        {/* Ending These Terms */}
        <section>
          <h2 className="text-xl font-semibold">Ending These Terms</h2>
          <p>
            You may end your agreement with bodhiga at any time by deactivating your account and ceasing use of the
            Services. For details on how we handle your information, please refer to our Privacy Policy.
          </p>
          <p>
            bodhiga may suspend or terminate your account, or stop providing some or all of the Services, if we
            reasonably determine that: (i) you have violated these Terms or our Rules and Policies, (ii) your actions
            create risk or potential legal liability for us, (iii) your account is associated with unlawful activity,
            (iv) your account has been inactive for an extended period, or (v) continuing to provide the Services to you
            is no longer commercially feasible. Where possible, we will notify you by email or when you next attempt to
            access your account, based on the situation. If terminated, these Terms will end, including your license to
            use the Services, except for the following sections, which will remain in effect: Sections 2, 3, 5, 6, and
            the misuse provisions of Section 4 (“Misuse of the Services”).
          </p>
          <p>
            If you believe your account was terminated in error, please contact{" "}
            <span className="text-blue-600 dark:text-blue-500 hover:underline">support@bodhiga.com</span>. These Terms
            survive beyond the deactivation or termination of your account.
          </p>
        </section>

        <div className="divider divider-horizontal"></div>

        {/* Limitations of Liability */}
        <section>
          <h2 className="text-xl font-semibold">Limitations of Liability</h2>
          <p>
            By using the Services you agree that bodhiga, officers, directors, employees, agents representatives,
            partners and licensors, liability is limited to the maximum extent permissible in your country of residence.
          </p>
        </section>

        <div className="divider divider-horizontal"></div>

        {/* Liquidated Damages */}
        <section>
          <h2 className="text-xl font-semibold">Liquidated Damages</h2>
          <p>
            Protecting our users’ data and bodhiga’s system resources is a priority. To the extent permitted by law, if
            you violate these Terms or encourage or assist others to do so, you agree that, in addition to other legal
            remedies available to us, you will be jointly and severally liable for liquidated damages in cases where you
            request, view, or access more than 1,000,000 pieces of Content within a 24-hour period. The damages are set
            at £15,000 GBP per 1,000,000 pieces of Content accessed within such a period. You agree that this amount is
            (i) a reasonable estimate of damages, (ii) not a penalty, and (iii) not intended to limit our rights to
            pursue further legal or equitable remedies, including statutory damages or other forms of relief.
          </p>
          <p>
            Additionally, repeated violations of these Terms may cause irreparable harm to bodhiga, entitling us to
            injunctive and/or equitable relief in addition to any monetary damages.
          </p>
        </section>

        <div className="divider divider-horizontal"></div>

        {/* General */}
        <section>
          <h2 className="text-xl font-semibold">General</h2>
          <p>
            We may update these Terms from time to time. Changes will not apply retroactively, and the latest version
            will always be available at [link to terms page], which will govern our relationship with you. Except for
            changes addressing new features or required for legal reasons, we will notify you of any updates to these
            Terms that affect the rights or obligations of any party through a notification on the Services or by email
            to the address associated with your account. By continuing to access or use the Services after changes take
            effect, you agree to be bound by the revised Terms.
          </p>
          <p>
            To the extent allowed by law, any disputes arising from or related to these Terms or the Services—including
            disputes involving other users or third parties regarding Content shared on the Services—will be resolved
            exclusively in a competent court. However, bodhiga reserves the right to initiate claims in any competent
            court within your country of residence if it is appropriate and legally permissible. To the fullest extent
            permitted, you agree to waive your right to participate in any class action, collective action, or
            representative action as a plaintiff or class member.
          </p>
          <p>
            You and bodhiga agree that any claims or legal actions relating to these Terms must be filed within one (1)
            year from the date the event or facts giving rise to the dispute occurred. Beyond this period, to the extent
            permitted by applicable law, such claims or causes of action are permanently barred.
          </p>
          <p>
            If any provision of these Terms is found to be invalid or unenforceable, it will be modified or limited to
            the minimum extent required, and all other provisions of these Terms will continue in full force. Failure by
            bodhiga to enforce any right or provision of these Terms will not constitute a waiver of that right or
            provision.
          </p>
          <p>
            If you have any questions regarding these Terms, please contact us at{" "}
            <span className="text-blue-600 dark:text-blue-500 hover:underline">support@bodhiga.com</span>.
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService;
