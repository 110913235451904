import { useGetSignedUrls } from "@/hooks/media";
import { FileObject } from "@/src/types";
import { useEffect, useRef, useState } from "react";
import Icon from "../icon/Icon";
import ImageInput from "../input/ImageInput";
import CustomImage from "../media/CustomImage";

interface ProfileCoverPictureProps {
  src: string | undefined;
  editable?: boolean;
  onChange?: (file: FileObject[]) => void;
  styles?: string;
  isUploading?: boolean;
}

const ProfileCoverPicture = ({
  src,
  editable,
  onChange,
  styles = "w-full h-40 md:h-60",
  isUploading,
}: ProfileCoverPictureProps) => {
  const [previewSrc, setPreviewSrc] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { data: signedUrls } = useGetSignedUrls(isValidS3Url(src) ? [src as string] : []);

  useEffect(() => {
    if (signedUrls && signedUrls.length > 0) {
      setPreviewSrc(signedUrls[0].signedUrl);
      setIsLoading(false);
    } else {
      setPreviewSrc(src);
      setIsLoading(false);
    }
  }, [signedUrls, src]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileObject: FileObject = {
        file,
        id: file.name,
        preview: URL.createObjectURL(file),
        imageType: "profileCover",
      };
      setPreviewSrc(fileObject.preview);
      if (onChange) {
        onChange([fileObject]);
      }
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      const fileObject: FileObject = {
        file,
        id: file.name,
        preview: URL.createObjectURL(file),
        imageType: "profileCover",
      };
      setPreviewSrc(fileObject.preview);
      if (onChange) {
        onChange([fileObject]);
      }
      event.dataTransfer.clearData();
    }
  };

  const handleImageError = () => {
    setPreviewSrc(undefined);
  };

  return (
    <div
      className={`relative ${styles} ${editable ? "cursor-pointer" : ""}`}
      onDrop={editable ? handleDrop : undefined}
      onDragOver={editable ? (e) => e.preventDefault() : undefined}
      onClick={editable ? () => fileInputRef.current?.click() : undefined}
    >
      <div className="relative w-full h-full rounded-xl overflow-hidden shadow flex items-center justify-center bg-secondary/50 text-accent-content">
        {isLoading || isUploading ? (
          <div className="w-full h-full flex items-center justify-center bg-neutral">
            <span className="loading loading-spinner"></span>
          </div>
        ) : previewSrc ? (
          <div className="w-full h-full">
            <CustomImage
              src={previewSrc}
              alt="Profile Cover"
              className="w-full h-full object-cover object-center aspect-[3/1]"
              onError={handleImageError}
            />
          </div>
        ) : // no photo
        null}
      </div>
      {editable && (
        <>
          <div
            className="absolute inset-0 flex items-center justify-center bg-gray-800/25 cursor-pointer rounded-xl"
            onClick={(e) => {
              e.stopPropagation();
              fileInputRef.current?.click();
            }}
          >
            <Icon name="add_a_photo" color="#FFFFFF" size={32} fill />
          </div>
          <ImageInput onChange={handleFileChange} ref={fileInputRef} />
        </>
      )}
    </div>
  );
};

export default ProfileCoverPicture;

const isValidS3Url = (url: string | undefined): boolean => {
  if (!url) return false;
  if (url === "https://placehold.co/1500x500/png") return false;

  return true;
};
