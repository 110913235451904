import { useFollow } from "@/hooks/user";
import Icon from "../icon/Icon";

interface FollowButtonProps {
  userId: string;
  followingUserId: string;
  className?: string;
  iconSize?: number;
}

export const FollowButton = ({ userId, followingUserId, className, iconSize = 22 }: FollowButtonProps) => {
  const {
    following,
    isLoading: isFollowLoading,
    handleFollowClick,
  } = useFollow({
    userId,
    followingUserId,
  });

  return (
    <button
      className={`btn btn-circle bg-transparent border-0 text-success ${className}`}
      onClick={handleFollowClick}
      disabled={isFollowLoading}
    >
      {isFollowLoading ? (
        <span className="loading loading-spinner text-textPrimary"></span>
      ) : following ? (
        <>
          <Icon name="person_remove" size={iconSize} color="" fill />
        </>
      ) : (
        <>
          <Icon name="person_add" size={iconSize} color="#4b5563" fill />
        </>
      )}
    </button>
  );
};
