import { ReactNode, useEffect, useState } from "react";
import { ActiveHomeFeedContext } from "../hooks/activeHomeFeed";
import { ActiveHomeFeed } from "../types";

export const ActiveHomeFeedProvider = ({ children }: { children: ReactNode }) => {
  const [activeFeed, setActiveFeed] = useState<ActiveHomeFeed>(() => {
    return (localStorage.getItem("activeFeed") as ActiveHomeFeed) || "discover";
  });

  useEffect(() => {
    localStorage.setItem("activeFeed", activeFeed);
  }, [activeFeed]);

  return (
    <ActiveHomeFeedContext.Provider value={{ activeFeed, setActiveFeed }}>{children}</ActiveHomeFeedContext.Provider>
  );
};
