import { useState } from "react";
import { ActiveOrderFeedContext } from "../hooks/activeOrderFeed";
import { ActiveOrderFeed } from "../types";

export const ActiveOrderFeedProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [activeTab, setActiveTabState] = useState<ActiveOrderFeed>(
    () => (localStorage.getItem("activeTab") as ActiveOrderFeed) ?? "ordersMade",
  );

  const setActiveTab = (tab: ActiveOrderFeed) => {
    setActiveTabState(tab);
    localStorage.setItem("activeTab", tab);
  };

  return (
    <ActiveOrderFeedContext.Provider value={{ activeTab, setActiveTab }}>{children}</ActiveOrderFeedContext.Provider>
  );
};
