import { useGetSessionEvent, useQuerySessionEventsBySessionId } from "@/hooks/session";
import { formatDate, formatDateRange } from "@/utils/date";
import { SessionEvent } from "@yoga-app/types";
import { useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useSearchParams } from "react-router-dom";
import Icon from "../../../../components/icon/Icon";
import InfiniteList from "../../../../components/list/InfiniteList";

interface SessionEventSelectorProps {
  sessionId: string;
  userId: string;
  selectedSessionEvent?: SessionEvent;
  onSessionEventChange: (sessionEvent: SessionEvent) => void;
}

const todaysDate = new Date();
todaysDate.setHours(0, 0, 0, 0);

const SessionEventSelector = ({
  sessionId,
  userId,
  selectedSessionEvent,
  onSessionEventChange,
}: SessionEventSelectorProps) => {
  const [searchParams, _setSearchParams] = useSearchParams();
  const sessionEventId = searchParams.get("sessionEventId");
  const [fromDate, setFromDate] = useState<string>();
  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null);

  // Get the specific session event if sessionEventId is passed in through the url
  const { data: specificEvent, isLoading: isSpecificEventLoading } = useGetSessionEvent(
    userId,
    sessionEventId as string,
  );

  useEffect(() => {
    if (!sessionEventId || !isSpecificEventLoading) {
      if (specificEvent) {
        const eventStartDate = new Date(specificEvent.startDateTime);
        eventStartDate.setHours(0, 0, 0, 0);
        setFromDate(eventStartDate.toISOString());
      } else {
        setFromDate(todaysDate.toISOString());
      }
    }
  }, [specificEvent, isSpecificEventLoading, sessionEventId]);

  // Fetch past events
  const {
    data: pastData,
    fetchNextPage: fetchPastPage,
    hasNextPage: hasMorePastEvents,
    isFetching: isFetchingPast,
    refetch: refetchPastEvents,
  } = useQuerySessionEventsBySessionId(sessionId, fromDate ?? "", "PAST", { limit: 10 });

  // Fetch future events
  const {
    data: futureData,
    fetchNextPage: fetchFuturePage,
    hasNextPage: hasMoreFutureEvents,
    isFetching: isFetchingFuture,
    refetch: refetchFutureEvents,
  } = useQuerySessionEventsBySessionId(sessionId, fromDate ?? "", "FUTURE", { limit: 10 });

  const [isModalOpen, setModalOpen] = useState(false);
  const [shouldScroll, setShouldScroll] = useState(true);

  const toggleDropdown = () => {
    setModalOpen(!isModalOpen);
    if (!isModalOpen) {
      setShouldScroll(true);
    }
  };

  const handleSelectEvent = (event: SessionEvent) => {
    onSessionEventChange(event);
    setModalOpen(false);
    setShouldScroll(false);
  };

  const pastEvents = useMemo(() => pastData?.pages.flatMap((page) => page.items) || [], [pastData]);
  const futureEvents = useMemo(() => futureData?.pages.flatMap((page) => page.items) || [], [futureData]);

  // Combine past and future events, sorted by date
  const combinedEvents = useMemo(
    () =>
      [...pastEvents, ...futureEvents].sort(
        (a, b) => new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime(),
      ),
    [pastEvents, futureEvents],
  );

  const selectedIndex = useMemo(
    () => combinedEvents.findIndex((event) => event.id === selectedSessionEvent?.id),
    [combinedEvents, selectedSessionEvent],
  );

  useEffect(() => {
    if (
      combinedEvents.length === 0 &&
      !isFetchingPast &&
      !isFetchingFuture &&
      !pollingIntervalRef.current &&
      !!fromDate
    ) {
      /**
       * Polling interval to refetch past and future events every 2 seconds when there are no events.
       * A session should always have atleast 1 event, we're doing this polling where no events are present yet due to the delay in creating session events on Session Create
       */
      pollingIntervalRef.current = setInterval(async () => {
        await Promise.all([refetchPastEvents(), refetchFutureEvents()]);
      }, 2000);

      setTimeout(() => {
        if (pollingIntervalRef.current) {
          clearInterval(pollingIntervalRef.current);
          pollingIntervalRef.current = null;
        }
      }, 30000);
    }

    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
      }
    };
  }, [combinedEvents.length, isFetchingPast, isFetchingFuture, refetchPastEvents, refetchFutureEvents, fromDate]);

  useEffect(() => {
    if (!selectedSessionEvent) {
      if (specificEvent) {
        onSessionEventChange(specificEvent);
      } else {
        const nextUpcomingEvent = futureEvents[0];
        const defaultEvent = nextUpcomingEvent || combinedEvents[0];
        if (defaultEvent) {
          onSessionEventChange(defaultEvent);
        }
      }
    }
  }, [futureEvents, combinedEvents, selectedSessionEvent, onSessionEventChange, specificEvent]);

  useEffect(() => {
    if (shouldScroll) {
      setShouldScroll(false);
    }
  }, [combinedEvents, shouldScroll]);

  if (!combinedEvents.length && !isFetchingPast && !isFetchingFuture) {
    return null;
  }

  return (
    <div className="relative w-full">
      {combinedEvents.length > 1 && (
        <button
          onClick={toggleDropdown}
          className="absolute -right-3 -bottom-2 btn btn-ghost px-4 py-2 flex items-center hover:bg-transparent z-50"
          disabled={combinedEvents.length === 1}
        >
          <Icon name="edit_calendar" size={22} color="#4361ee" className="p-0" />
        </button>
      )}

      {!selectedSessionEvent && (isFetchingPast || isFetchingFuture) ? (
        // Skeleton Card for Selected Event
        <div className="w-full h-full skeleton"></div>
      ) : (
        selectedSessionEvent && (
          <div
            className={`p-2 w-20 h-20 glass rounded-xl text-center items-center justify-center bg-base-100 shadow-lg ${combinedEvents.length > 1 ? "cursor-pointer hover:bg-accent" : ""}`}
            onClick={toggleDropdown}
          >
            <div className="text-secondary mt-1 select-none">
              {formatDate(new Date(selectedSessionEvent.startDateTime), "MMM")}
            </div>
            <div className="font-bold text-lg text-error select-none">
              {formatDate(new Date(selectedSessionEvent.startDateTime), "DD")}
            </div>
          </div>
        )
      )}

      {isModalOpen && (
        <Modal
          setModalOpen={setModalOpen}
          combinedEvents={combinedEvents}
          fetchFuturePage={fetchFuturePage as unknown as () => Promise<void>}
          fetchPastPage={fetchPastPage as unknown as () => Promise<void>}
          hasMoreFutureEvents={hasMoreFutureEvents}
          hasMorePastEvents={hasMorePastEvents}
          isFetchingPast={isFetchingPast}
          isFetchingFuture={isFetchingFuture}
          selectedSessionEvent={selectedSessionEvent}
          handleSelectEvent={handleSelectEvent}
          shouldScroll={shouldScroll}
          selectedIndex={selectedIndex}
        />
      )}
    </div>
  );
};

export default SessionEventSelector;

interface ModalProps {
  setModalOpen: (isOpen: boolean) => void;
  combinedEvents: SessionEvent[];
  fetchFuturePage: () => Promise<void>;
  fetchPastPage: () => Promise<void>;
  hasMoreFutureEvents?: boolean;
  hasMorePastEvents?: boolean;
  isFetchingPast: boolean;
  isFetchingFuture: boolean;
  selectedSessionEvent?: SessionEvent;
  handleSelectEvent: (event: SessionEvent) => void;
  shouldScroll?: boolean;
  selectedIndex?: number;
}

const Modal = ({
  setModalOpen,
  combinedEvents,
  fetchFuturePage,
  fetchPastPage,
  hasMoreFutureEvents,
  hasMorePastEvents,
  isFetchingPast,
  isFetchingFuture,
  selectedSessionEvent,
  handleSelectEvent,
  shouldScroll,
  selectedIndex,
}: ModalProps) => {
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // Close modal if clicking outside the modal content
    if ((e.target as HTMLElement).classList.contains("modal-backdrop")) {
      setModalOpen(false);
    }
  };

  return createPortal(
    <div
      className="fixed inset-0 bg-neutral bg-opacity-50 flex items-center justify-center z-[9999] modal-backdrop"
      onClick={handleBackdropClick}
    >
      <div
        className="bg-base-100 h-full rounded-xl p-6 w-96 overflow-y-scroll shadow-lg relative"
        style={{
          maxHeight: `${Math.min(combinedEvents.length, 3) * 80 + 50}px`,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={() => setModalOpen(false)}
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 z-[9999]"
        >
          ✕
        </button>
        <InfiniteList
          data={combinedEvents}
          fetchNextPage={async () => {
            if (!shouldScroll) {
              await fetchFuturePage();
            }
          }}
          fetchPreviousPage={async () => {
            if (!shouldScroll) {
              await fetchPastPage();
            }
          }}
          hasNextPage={!!hasMoreFutureEvents}
          hasPreviousPage={!!hasMorePastEvents}
          isFetching={isFetchingPast || isFetchingFuture}
          scrollToIndex={shouldScroll ? selectedIndex : undefined}
          className="h-full"
          CardComponent={({ item }: { item: SessionEvent }) => (
            <SessionEventCard
              item={item}
              selectedSessionEvent={selectedSessionEvent}
              handleSelectEvent={(event) => {
                handleSelectEvent(event);
                setModalOpen(false); // Close modal when an event is selected
              }}
            />
          )}
        />
      </div>
    </div>,
    document.body,
  );
};

interface SessionEventCardProps {
  item: SessionEvent;
  selectedSessionEvent?: SessionEvent;
  handleSelectEvent: (event: SessionEvent) => void;
}

const SessionEventCard = ({ item, selectedSessionEvent, handleSelectEvent }: SessionEventCardProps) => {
  return (
    <div
      onClick={() => handleSelectEvent(item)}
      className={`dropdown-content bg-base-100 rounded-xl flex items-center justify-between p-3 sm:p-4 cursor-pointer z-[999] relative ${
        selectedSessionEvent?.id === item.id ? "bg-blue-100" : "hover:bg-gray-200"
      }`}
    >
      <div className="flex items-center space-x-4">
        <div className="text-center text-secondary">
          <div className="text-sm sm:text-lg font-bold">{formatDate(new Date(item.startDateTime), "MMM DD")}</div>
          <div className="text-sm">{formatDate(new Date(item.startDateTime), "EEEE")}</div>
        </div>

        <div>
          <div className="text-sm sm:text-md font-semibold text-secondary">{item.title}</div>
          <div className="text-xs sm:text-sm text-gray-400">
            {formatDateRange(item.startDateTime, item.endDateTime)}
          </div>
        </div>
      </div>

      {/* Display "Selected" if this is the currently selected item */}
      <div className="flex items-center">
        <Icon
          name={selectedSessionEvent?.id === item.id ? "check_box" : "check_box_outline_blank"}
          size={24} // Adjust size as needed
          color={selectedSessionEvent?.id === item.id ? "#24b47e" : "gray"}
          className="ml-2"
          fill
        />
      </div>
    </div>
  );
};
