import Icon from "../icon/Icon";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title: string;
  className?: string;
}

const Modal = ({ isOpen, onClose, children, title, className }: ModalProps) => {
  if (!isOpen) return null;

  return (
    <dialog id="modal" className={`modal modal-bottom z-[100] md:modal-middle ${isOpen ? "modal-open" : ""}`}>
      <div className={`modal-box relative max-h-[75%] md:max-w-screen-lg flex flex-col bg-base-100 p-0 ${className}`}>
        <div className="z-50 w-full flex flex-row px-4 pt-4 pb-2 justify-between border-b-[1px] border-borderSecondary/30">
          <h2 className="text-xl font-bold tracking-wide text-textPrimary flex justify-center items-center">{title}</h2>

          <button className="btn btn-circle z-50 border-0 bg-inherit hover:border-secondary" onClick={onClose}>
            <Icon name="close" />
          </button>
        </div>

        <div className="overflow-y-auto no-scrollbar py-4 h-full">{children}</div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button onClick={onClose} className="cursor-default">
          close
        </button>
      </form>
    </dialog>
  );
};

export default Modal;
