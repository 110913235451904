import { FileObject } from "@/src/types";
import { useMutation } from "@tanstack/react-query";
import { Entity, ImageSizes } from "@yoga-app/types";
import config from "../config";
import { request } from "../utils/api";
import { trpc } from "../utils/trpc";
import { useAuth } from "./auth";

interface UploadResponse {
  files: ImageSizes[];
}

export const useFileUpload = () => {
  const { getUserId, getAccessToken } = useAuth();
  const utils = trpc.useUtils();

  const uploadFiles = async (files: FileObject[]): Promise<Entity[]> => {
    if (!files.length) return [];

    const userId = getUserId();

    try {
      const signedUrls = await utils.client.media.generateFileUploadSignedUrl.mutate({
        files: files.map((file) => ({
          fileName: file.file.name,
          fileType: file.file.type,
        })),
      });

      await Promise.all(
        signedUrls.map(({ signedUrl }, index) =>
          fetch(signedUrl, {
            method: "PUT",
            body: files[index].file,
            headers: {
              "Content-Type": files[index].file.type,
            },
          }),
        ),
      );

      const response = await request<UploadResponse>(`${config.api.url}/media`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${await getAccessToken()}`,
        },
        body: JSON.stringify({
          files: signedUrls.map(({ key }, index) => ({
            key,
            type: files[index].file.type,
            imageType: files[index].imageType,
          })),
          userId,
        }),
      });

      return response.files.map((imageSizes, index) => {
        const isImage = files[index].file.type.startsWith("image/");

        return {
          id: files[index].id,
          type: isImage ? "image" : "video",
          url: imageSizes,
          position: index,
        };
      });
    } catch (error) {
      console.error("Error uploading files:", error);
      throw error;
    }
  };

  const { mutateAsync, isLoading } = useMutation(uploadFiles);

  return {
    uploadFiles: mutateAsync,
    isUploading: isLoading,
  };
};

export const useGetSignedUrls = (keys: string[]) => {
  const expiresIn = 3600 * 1000;
  const cacheTime = expiresIn * 2;

  return trpc.media.generateSignedUrls.useQuery(
    { keys },
    {
      enabled: keys.length > 0,
      cacheTime: cacheTime,
      staleTime: expiresIn,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
  );
};
