import logo from "@/assets/logo180.png";
import { useAuth } from "@/hooks/auth";
import { useQueryChatsWithUnreadMessages } from "@/hooks/chat";
import { useGetNotifications } from "@/hooks/notification";
import { dontShowSidebar } from "@/utils/layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useGetUser } from "../../hooks/user";
import Icon from "../icon/Icon";
import ProfilePicture from "../profile/ProfilePicture";

// Pages to exclude navbar links
const excludeNavBarLinks = ["/sign-in", "/sign-up"];

interface NavbarProps {
  toggleSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  sidebarOpen: boolean;
}

const Navbar = ({ toggleSidebar, sidebarOpen }: NavbarProps) => {
  const location = useLocation();
  const { isLoading, isAuthenticated, getUserId, signOut } = useAuth();
  const navigate = useNavigate();
  const userId = getUserId() as string;
  const { data: user, isLoading: isUserLoading } = useGetUser(userId);

  const handleSignOut = () => {
    signOut();
    navigate("/");
  };

  return (
    <div className="navbar sticky inset-x-0 top-0 z-50 h-16 w-full bg-neutral border-b border-gray-300 py-1 px-2.5 rounded-t-xl">
      <header className="relative w-full">
        <div className="w-full">
          <div className="flex h-16 items-center justify-between">
            <div className="flex items-center">
              {dontShowSidebar.includes(location.pathname) || !isAuthenticated ? null : (
                <button
                  onClick={() => toggleSidebar(!sidebarOpen)}
                  className="btn btn-ghost lg:hidden p-0 mr-2 px-2 flex text-center"
                >
                  <Icon name="menu" color="#212529" size={22} />
                </button>
              )}

              <Link to="/" className={`btn btn-ghost pl-1`}>
                <img src={logo} alt="bodhiga Logo" className="h-24 -mt-6 w-auto object-fill overflow-hidden" />
              </Link>
            </div>

            {!excludeNavBarLinks.includes(location.pathname) && (
              <div className="flex items-center space-x-3">
                {isLoading || (isAuthenticated && isUserLoading) ? null : isAuthenticated && user ? (
                  // user logged in
                  <>
                    {/* Notifications */}
                    <NotificationsButton userId={userId} />

                    {/* Messages */}
                    <MessagesButton />

                    {/* Add content */}
                    <button
                      className="btn btn-primary font-semibold text-base-100 p-1 w-8 h-8 min-h-0 rounded-full items-center flex"
                      onClick={() => navigate(`/create-content`)}
                    >
                      <Icon name="add" size={18} color="#F8F9FAs" />
                    </button>

                    {/* User dropdown */}
                    <div className="dropdown dropdown-end">
                      <label tabIndex={0} className="btn btn-ghost btn-circle">
                        <ProfilePicture
                          user={user}
                          src={user?.profilePic?.[150]}
                          styles={"w-12 h-12"}
                          avatarSize="text-sm"
                        />
                      </label>
                      <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-neutral-100 rounded-box w-52">
                        <li>
                          <Link to={`/${user?.username}`} className="btn btn-ghost text-md hover:bg-gray-300">
                            Profile
                          </Link>
                        </li>

                        <li>
                          <button className="btn btn-ghost text-md hover:bg-gray-300" onClick={handleSignOut}>
                            Sign out
                          </button>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : (
                  // user not logged in
                  <>
                    {/* Menu dropdown on smaller screens */}
                    <div className="dropdown dropdown-end lg:hidden">
                      <label tabIndex={0} className="btn btn-ghost">
                        <Icon name="menu" color="#212529" />
                      </label>
                      <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                        <li>
                          <button className="btn btn-ghost text-md" onClick={() => navigate("/sign-in")}>
                            Sign in
                          </button>
                        </li>

                        <li>
                          <button className="btn btn-ghost text-md" onClick={() => navigate("/sign-up")}>
                            Create account
                          </button>
                        </li>
                      </ul>
                    </div>
                    {/* Menu items on larger screens */}
                    <div className="hidden lg:flex lg:items-center lg:space-x-4">
                      <button className="btn btn-ghost text-md" onClick={() => navigate("/sign-in")}>
                        Sign in
                      </button>
                      <>
                        <div className="divider divider-horizontal"></div>
                        <button className="btn btn-ghost text-md" onClick={() => navigate("/sign-up")}>
                          Create account
                        </button>
                      </>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navbar;

const MessagesButton = () => {
  const navigate = useNavigate();
  const { data: unreadChatsData } = useQueryChatsWithUnreadMessages({ limit: 99 });
  const unreadChats = unreadChatsData?.pages.flatMap((page) => page.items) || [];
  const unreadCount = unreadChats.length;
  const displayUnreadCount = unreadCount >= 100 ? "99+" : unreadCount;

  return (
    <div className="dropdown dropdown-end">
      <button
        className="btn btn-ghost h-8 w-6 min-h-0 min-w-0 rounded-full items-center flex"
        onClick={() => navigate("/messages")}
      >
        <div className="indicator">
          <Icon name="chat_bubble" size={24} color="#212529" />
          {unreadCount > 0 && (
            <span className="indicator-item badge badge-error text-base-100 w-5 h-5 min-h-0 p-1 min-w-0 text-xs">
              {displayUnreadCount}
            </span>
          )}
        </div>
      </button>
    </div>
  );
};

interface NotificationsButtonProps {
  userId: string;
}

const NotificationsButton = ({ userId }: NotificationsButtonProps) => {
  const { data } = useGetNotifications(userId, { limit: 99 });
  const notifications = data?.pages.flatMap((page) => page.items) || [];
  const unreadCount = notifications.filter((n) => n.status === "UNREAD").length;
  const displayUnreadCount = unreadCount >= 100 ? "99+" : unreadCount;

  return (
    <Link
      to={"/notifications"}
      className="btn btn-ghost h-8 w-6 min-h-0 min-w-0 rounded-full items-center flex hover:bg-gray-200"
    >
      <div className="indicator">
        <Icon name="notifications" size={24} color="#212529" />
        {unreadCount > 0 && (
          <span className="indicator-item badge badge-error text-base-100 w-5 h-5 min-h-0 p-1 min-w-0 text-xs">
            {displayUnreadCount}
          </span>
        )}
      </div>
    </Link>
  );
};
