import { useGetSignedUrls } from "@/hooks/media";
import { useAuth } from "@/src/hooks/auth";
import PackageItemList from "@/src/pages/content/features/package/packageItem/PackageItemList";
import SessionDetail from "@/src/pages/content/features/session/ContentCardSessionDetail";
import { getImageEntityKeys } from "@/utils/media";
import { isImageEntity, Package, Post, Session, SessionEvent, User } from "@yoga-app/types";
import { useMemo } from "react";

interface ContentContentProps {
  // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
  content: Post | Session | Package;
  user: User;
  selectedSessionEvent?: SessionEvent;
  onSessionEventChange?: (sessionEvent: SessionEvent) => void;
}

const ContentContent = ({ content, selectedSessionEvent, onSessionEventChange }: ContentContentProps) => {
  const { getUserId } = useAuth();
  const isSession = content.type === "SESSION";
  const isPackage = content.type === "PACKAGE";
  const session = isSession ? (content as Session) : undefined;
  const isOwner = content.userId === getUserId();
  const imageEntities = content.entities?.filter(isImageEntity) || [];
  const imageEntityKeys = getImageEntityKeys(imageEntities);
  const { data: signedUrls = [] } = useGetSignedUrls(imageEntityKeys);

  const processedText = useMemo(() => {
    const text = content.text || content.shortText;
    if (!text) return undefined;
    return replaceImageKeysWithUrls(text, signedUrls);
  }, [content.text, content.shortText, signedUrls]);

  // TODO: if there are many images in a row is it possible to have a carousel?

  return (
    <div className="block px-4 pt-1 pb-0">
      {isSession && session && (
        <SessionDetail
          session={session}
          isOwner={isOwner}
          selectedSessionEvent={selectedSessionEvent}
          onSessionEventChange={onSessionEventChange as (sessionEvent: SessionEvent) => void}
        />
      )}

      {isPackage && <PackageItemList packageId={content.id} />}

      {processedText && (
        <div
          className="prose prose-sm sm:prose-base focus:outline-none w-full h-full"
          dangerouslySetInnerHTML={{ __html: processedText }}
        ></div>
      )}

      {/* {isMediaContent && (
        <div className="w-full aspect-[1/1] p-4 pb-0">
          <MediaCarousel entities={content.entities!} />
        </div>
      )} */}
    </div>
  );
};

export default ContentContent;

const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const replaceImageKeysWithUrls = (text: string, signedUrls: { key: string; signedUrl: string }[]) => {
  if (!signedUrls.length) return text;

  const sortedKeys = signedUrls.sort((a, b) => b.key.length - a.key.length);
  const pattern = new RegExp(sortedKeys.map(({ key }) => escapeRegExp(key)).join("|"), "g");

  const urlMap = Object.fromEntries(sortedKeys.map(({ key, signedUrl }) => [key, signedUrl]));

  return text.replace(pattern, (match) => urlMap[match]);
};
