import { createContext, useContext } from "react";
import { ActiveHomeFeed } from "../types";

interface ActiveHomeFeedContextProps {
  activeFeed: ActiveHomeFeed;
  setActiveFeed: (feed: ActiveHomeFeed) => void;
}
export const ActiveHomeFeedContext = createContext<ActiveHomeFeedContextProps | undefined>(undefined);

export const useActiveHomeFeedContext = () => {
  const context = useContext(ActiveHomeFeedContext);
  if (!context) {
    throw new Error("useActiveHomeFeedContext must be used within a ActiveHomeFeedProvider");
  }
  return context;
};
