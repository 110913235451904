import React, { useCallback, useEffect, useState } from "react";
import Icon from "../icon/Icon";

export type CustomImageProps = {
  src: string;
  alt: string;
  className?: string;
  onError?: () => void;
};

const CustomImage: React.FC<CustomImageProps> = ({ src, alt, className = "", onError }) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const handleImageLoad = useCallback(() => {
    setLoaded(true);
    setError(false); // Reset error on successful load
  }, []);

  const handleImageError = useCallback(() => {
    if (src) {
      setError(true);
      if (onError) {
        onError();
      }
    }
  }, [onError, src]);

  useEffect(() => {
    const img = new Image();

    if (src) {
      // Reset states when the src changes
      setLoaded(false);
      setError(false);

      img.src = src;

      img.addEventListener("load", handleImageLoad);
      img.addEventListener("error", handleImageError);
    }

    // Cleanup to avoid memory leaks
    return () => {
      img.removeEventListener("load", handleImageLoad);
      img.removeEventListener("error", handleImageError);
    };
  }, [src, handleImageLoad, handleImageError]);

  if (error) {
    return (
      <div className={`w-full h-full flex flex-col text-center justify-center bg-gray-200 ${className}`}>
        <Icon name="image" />
      </div>
    );
  }

  return (
    <div className={`w-full h-full ${className}`}>
      {!loaded && <div className="w-full h-full skeleton"></div>}
      <img
        src={src}
        alt={alt}
        className={`w-full h-full ${className}`}
        loading={"lazy"}
        onLoad={handleImageLoad}
        onError={handleImageError}
      />
    </div>
  );
};

export default CustomImage;
