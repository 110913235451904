import Icon from "@/components/icon/Icon";
import { useNavigate } from "react-router-dom";

interface MessageButtonProps {
  userId: string;
  className?: string;
  iconSize?: number;
}

export const MessageButton = ({ userId, className, iconSize = 22 }: MessageButtonProps) => {
  const navigate = useNavigate();
  return (
    <button
      className={`btn btn-sm rounded-2xl bg-info border-0 shadow-none text-base-100 ${className}`}
      onClick={() => navigate(`/messages?userId=${userId}`)}
    >
      <Icon name="chat_bubble" size={iconSize} color="currentColor" fill />
    </button>
  );
};
