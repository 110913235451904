import { TRPCClientError } from "@trpc/client";
import { AppRouter } from "@yoga-app/trpc";
import createHttpError from "http-errors";

export function isTRPCClientError(cause: unknown): cause is TRPCClientError<AppRouter> {
  return cause instanceof TRPCClientError;
}

// Type guard to check if error is HttpError
export function isHttpError(error: unknown): error is createHttpError.HttpError {
  return error instanceof createHttpError.HttpError;
}

export const request = async <TResponse>(url: string, config: RequestInit): Promise<TResponse> => {
  const response = await fetch(url, config);

  // Handle non-2xx responses
  if (!response.ok) {
    const errorText = await response.text();
    let errorMessage: string;

    try {
      const errorJson = JSON.parse(errorText) as Error;
      errorMessage = errorJson.message || "An error occurred.";
    } catch {
      errorMessage = errorText || `Unexpected error occurred (status: ${response.status})`;
    }

    throw new Error(errorMessage);
  }

  try {
    return (await response.json()) as TResponse;
  } catch {
    throw new Error("Failed to parse server response.");
  }
};
