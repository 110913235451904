import { v4 } from "uuid";
import { z } from "zod";

export const visibilitySchema = z.enum(["PUBLIC", "PRIVATE"], { message: "Invalid visibility" });
export type Visibility = z.infer<typeof visibilitySchema>;

/**
 * Key images by width and value of the image url. This allows for having multiple sizes of the same image for different use cases.
 */
export const imageSizesSchema = z.record(z.string(), z.string());
export type ImageSizes = z.infer<typeof imageSizesSchema>;

const imageContentSchema = z.object({
  id: z.string().default(() => v4()),
  type: z.literal("image"),
  url: imageSizesSchema,
  position: z.number().min(0),
});

const videoContentSchema = z.object({
  id: z.string().default(() => v4()),
  type: z.literal("video"),
  url: imageSizesSchema,
  position: z.number().min(0),
});

export const entitySchema = z.union([imageContentSchema, videoContentSchema]);
export type Entity = z.infer<typeof entitySchema>;

export const contentTypeSchema = z.enum(["POST", "PACKAGE", "SESSION"]);
export type Content = z.infer<typeof contentTypeSchema>;

const tagsSchema = z.array(z.string().max(30, { message: "Hashtag should be no more than 30 characters long." }));

const reportApprovalStatusSchema = z.enum(["APPROVED", "PENDING", "REJECTED"]);
export type ReportApprovalStatus = z.infer<typeof reportApprovalStatusSchema>;

export const contentSchema = z.object({
  /**
   * A unique identifier for the content.
   */
  id: z.string().default(() => v4()),

  /**
   * The user id of the user who created the content.
   */
  userId: z.string({
    required_error: "userId is required",
    invalid_type_error: "userId must be a string",
  }),
  username: z.string(),

  /**
   * The text of the content.
   */
  text: z.string().max(10_000).optional(),

  /**
   * Short Text.
   * This is a preview of the text content at a capped length.
   */
  shortText: z.string().max(280).optional(),

  /**
   * Entities such as images, videos
   */
  entities: entitySchema.array().optional(),

  /**
   * Hashtags. These are used to categorise content. The idea is to be able to link a post with a hashtag which would allow you to find this post against a hashtag search
   */
  tags: tagsSchema.optional(),

  /**
   * Mentions. Tag users in a post, linking them to the post.
   * array of user ids
   */
  mentions: z.array(z.string()).optional(),

  /**
   * Visibility of the content
   */
  visibility: visibilitySchema,

  /**
   * price of the content, in the smallest currency unit e.g., 100p for £1
   */
  price: z.union([
    z.literal(0), // Allow a price of 0 for free content
    z
      .number({
        required_error: "Price is required",
        invalid_type_error: "Price must be a number",
      })
      //TODO: Is this a valid min amount?
      .min(230, { message: "The minimum price for paid content is £2.30" }), // Enforce minimum of 230 for paid content
  ]),

  /**
   * The type of content.
   * e.g., POST, PACKAGE, SESSION
   * Only used for internally knowning which record is which content type. Otherwise we'd have to have some logic everywhere which isn't necessary
   */
  type: contentTypeSchema,

  /**
   * The status of the content approval, if its been reported.
   * content which has been reported with an approval status of PENDING or REJECTED will not be visible to other users.
   */
  approvalStatus: reportApprovalStatusSchema.optional(),

  /**
   * Report reason
   */
  reportReason: z.string().optional(),

  /**
   * The date and time the content was created.
   *
   * The date is represented as an ISO 8601 date string.
   */
  createdAt: z.string().datetime({ offset: true }),
  /**
   * The date and time the content was last updated.
   *
   * The date is represented as an ISO 8601 date string.
   */
  updatedAt: z.string().datetime({ offset: true }),
});

export const isImageEntity = (entity: Entity): entity is z.infer<typeof imageContentSchema> => {
  return entity.type === "image";
};

export const isVideoEntity = (entity: Entity): entity is z.infer<typeof videoContentSchema> => {
  return entity.type === "video";
};

export const contentItemUri = z.object({
  /**
   * The type of content item.
   */
  type: z.enum(["POST", "PACKAGE", "SESSION"]),
  /**
   * The id of the content item.
   *
   * e.g., postId or sessionId
   */
  id: z.string(),
});

export type ContentItemUri = z.infer<typeof contentItemUri>;

export const likeUri = z.object({
  /**
   * The type of entity being liked.
   */
  type: z.enum(["POST", "COMMENT", "SESSION", "PACKAGE"]),
  /**
   * The id of the entity being liked.
   *
   * e.g., postId or commentId
   */
  id: z.string(),

  linkedContentItemUri: contentItemUri.optional(),
});
export type LikeUri = z.infer<typeof likeUri>;

export const likeSchema = z.object({
  uri: likeUri,
  /**
   * The user id of the user who liked the content.
   */
  userId: z.string(),
  createdAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
  updatedAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
});

export type Like = z.infer<typeof likeSchema>;

export const contentLikeSchema = z.object({
  contentItemUri: contentItemUri,
  /**
   * The user id of the user who owns the content.
   */
  userId: z.string(),
  likeCount: z.number().default(0),
  createdAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
  updatedAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
});

export type ContentLike = z.infer<typeof contentLikeSchema>;

export const paymentStatusSchema = z.enum(["PENDING", "COMPLETED", "FAILED", "REFUNDED"]);
export type PaymentStatus = z.infer<typeof paymentStatusSchema>;

/**
 * Payment details
 */
export const paymentSchema = z.object({
  /**
   * Payment id.
   */
  id: z.string(),
  /**
   * The user id of the user who made the payment.
   */
  userId: z.string(),
  username: z.string(),

  /**
   * The user id of the content owner.
   */
  contentOwnerUserId: z.string(),
  contentOwnerUsername: z.string(),
  amount: z.number(),
  /**
   * The id of the content item being paid for.
   * Currently the sessionEvent id
   */
  itemId: z.string().min(1),

  /**
   * The id of the payment intent.
   */
  paymentIntentId: z.string().optional(),

  /**
   * Reason for Refund.
   */
  refundReason: z
    .string()
    .trim()
    .min(5, "Reason must be at least 5 characters")
    .max(500, "Reason is too long")
    .refine((reason) => reason.trim().length > 0, {
      message: "Reason cannot be empty or just spaces",
    })
    .optional(),

  status: paymentStatusSchema,
  createdAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
  updatedAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
});

export type Payment = z.infer<typeof paymentSchema>;

export const paymentEventSchema = z.object({
  eventId: z.string(),
  createdAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
  updatedAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
});

export type PaymentEvent = z.infer<typeof paymentEventSchema>;
