const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto max-w-4xl p-6">
      <h1 className="text-4xl font-bold mb-6 text-center">Privacy Policy</h1>

      <div className="space-y-6 text-lg text-gray-700">
        {/* Information we collect and why */}
        <section>
          <h2 className="text-xl font-semibold">1. Information We Collect and Why</h2>
          <p>
            To create a bodhiga account and use our Services, we collect essential information. When you use paid
            features, additional details are necessary to process payments.
          </p>
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">1.1 Account Information</h3>
            <p>When setting up an account, we require:</p>
            <p className="italic">
              Username, password, email, and date of birth. Your username is always public and part of your profile,
              viewable by others.
            </p>
          </div>
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">1.2 Payment Information</h3>
            <p>For transactions, we may collect:</p>
            <p className="italic">
              Payment details such as credit/debit card numbers, expiration date, CVV, and billing address. We use
              secure third-party processors (e.g., Stripe) to handle payment transactions. For specific details on
              payment data processing, see Stripe’s policies.
            </p>
          </div>
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">1.3 Usage and Interaction Data</h3>
            <p>We collect data on your interactions with content and other users, such as:</p>
            <p className="italic">
              Content posted, liked, or commented on, users you follow, and purchases made. We may track your engagement
              with these interactions to provide and improve Services.
            </p>
          </div>
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">1.4 Transaction Details</h3>
            <p>
              To process payments and subscriptions, we track information like transaction dates, amounts, and renewal
              dates.
            </p>
          </div>
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">1.5 Location Information</h3>
            <p>You may choose to share location data, which we may use to personalise content suggestions.</p>
          </div>
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">1.6 Cookies and Similar Technologies</h3>
            <p>
              We use cookies to gather website usage data and improve Service functionality. Cookies are not essential
              for most parts of our Services, and you can adjust your browser settings to control cookie usage.
            </p>
          </div>
        </section>

        <div className="divider divider-horizontal"></div>

        {/* How we use your information */}
        <section>
          <h2 className="text-xl font-semibold">2. How We Use Your Information</h2>
          <p>We use collected information to operate, enhance, and personalise your experience with bodhiga.</p>
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">2.1 Service Operation and Personalisation</h3>
            <p>
              We rely on this information to provide and enhance Services, including showing relevant content,
              recommendations, and user interactions. It also helps others discover your profile and content.
            </p>
          </div>
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">2.2 Safety and Security</h3>
            <p>
              We use data to verify identities, combat fraud, prevent unauthorised use, and ensure safety across the
              platform.
            </p>
          </div>
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">2.3 Service Improvement</h3>
            <p>We analyse your activity to understand how bodhiga is used and identify areas for improvement.</p>
          </div>
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">2.4 Communication</h3>
            <p>We may contact you with service updates, policy changes, and—if you consent—marketing communications.</p>
          </div>
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">2.5 Troubleshooting</h3>
            <p>Information is used to resolve issues, enhance support, and maintain service quality.</p>
          </div>
          <p className="mt-4">
            For any inquiries, contact{" "}
            <span className="text-blue-600 dark:text-blue-500 hover:underline">support@bodhiga.com</span>.
          </p>
        </section>

        <div className="divider divider-horizontal"></div>

        {/* Sharing your information */}
        <section>
          <h2 className="text-xl font-semibold">3. Sharing Your Information</h2>
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">3.1 With Other Users</h3>
            <p>
              Content and profile details you share (e.g., username, profile picture) may be publicly visible. Comments
              and interactions with others may also be viewable by other users. Information shared with external
              platforms will be subject to those platforms’ policies.
            </p>
          </div>
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">3.2 With Third Parties and Integrations</h3>
            <p>
              We may share data with trusted third-party providers (e.g., payment processors) to facilitate Services.
              Certain integrations, like APIs, may share limited data with affiliated sites, apps, or services.
            </p>
          </div>
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">3.3 For Legal and Public Safety Purposes</h3>
            <p>
              We may use or disclose your information to comply with legal obligations, enforce platform safety, respond
              to legal requests, prevent fraud, and protect user rights. We may also share age and identity details to
              fulfill regulatory requirements.
            </p>
          </div>
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">3.4 In Case of Business Changes</h3>
            <p>
              In cases of mergers, acquisitions, or business transitions, information about users may be transferred or
              sold, subject to this Privacy Policy.
            </p>
          </div>
        </section>

        <div className="divider divider-horizontal"></div>

        {/* Data retention and deletion */}
        <section>
          <h2 className="text-xl font-semibold">4. Data Retention and Deletion</h2>
          <p>Information retention depends on service requirements, legal obligations, and safety considerations.</p>
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">4.1 Standard Retention</h3>
            <p>Most user information is retained until account closure.</p>
          </div>
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">4.2 Extended Retention</h3>
            <p>
              Transaction records may be kept longer, as legally required. In cases of policy violations, certain data
              (e.g., email) may be retained indefinitely to prevent repeat offenses.
            </p>
          </div>
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">4.3 Data Deletion</h3>
            <p>
              Upon account deactivation, your profile will be removed from view, but some information may persist in
              backups or be retained for legal reasons. Public content may continue to appear in places outside of
              bodhiga’s control.
            </p>
          </div>
        </section>

        <div className="divider divider-horizontal"></div>

        {/* Your Rights and Choices */}
        <section>
          <h2 className="text-xl font-semibold">5. Your Rights and Choices</h2>
          <p>You have control over your personal information and can request changes or deletion:</p>
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">5.1 Access and Update</h3>
            <p>Edit your profile and account information directly on bodhiga.</p>
          </div>
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">5.2 Account Deactivation</h3>
            <p>
              Contact us at{" "}
              <span className="text-blue-600 dark:text-blue-500 hover:underline">support@bodhiga.com</span> to
              deactivate your account. Your data will be queued for deletion, and your profile will no longer be
              viewable.
            </p>
          </div>
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">5.3 Request Assistance</h3>
            <p>
              You can request access, updates, or deletion of personal data on behalf of yourself or others (where
              authorised) by contacting us. Verification may be required for certain requests.
            </p>
          </div>
        </section>

        <div className="divider divider-horizontal"></div>

        {/* Cross-border data transfers */}
        <section>
          <h2 className="text-xl font-semibold">6. Cross-Border Data Transfers</h2>
          <p>
            To deliver consistent, reliable services, we may transfer your data to locations worldwide, using secure
            data centres, cloud providers, and third-party services. These providers are chosen for their compliance
            with privacy standards.
          </p>
        </section>

        <div className="divider divider-horizontal"></div>

        {/* Audience restrictions */}
        <section>
          <h2 className="text-xl font-semibold">7. Audience Restrictions</h2>
          <p>
            Our Services are not intended for children, and users must be at least 13 years old to use bodhiga. We do
            not knowingly collect information from children under 13. If you become aware that a minor has provided
            personal data, please contact us. Accounts for users under 13 will be terminated, and data will be deleted.
          </p>
        </section>

        <div className="divider divider-horizontal"></div>

        {/* Privacy policy updates */}
        <section>
          <h2 className="text-xl font-semibold">8. Privacy Policy Updates</h2>
          <p>
            This Privacy Policy governs bodhiga’s data handling practices. We may update it to reflect new practices or
            legal requirements. Any substantial changes will be communicated via email or in-platform notifications.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
