import MaxWidthWrapper from "../layout/MaxWidthWrapper";

const PageNotFound = () => {
  return (
    <MaxWidthWrapper className="flex items-center justify-center h-screen">
      <div className="text-center">
        <h1 className="text-3xl font-bold mb-4">Page Not Found</h1>
        <p className="text-lg">We can't find what you're looking for</p>
      </div>
    </MaxWidthWrapper>
  );
};

export default PageNotFound;
