import { useAuth } from "@/hooks/auth";
import { createTRPCReact, httpBatchLink } from "@trpc/react-query";
import type { AppRouter } from "@yoga-app/trpc";
import { useState } from "react";
import config from "../config";

export const trpc = createTRPCReact<AppRouter>();

export const useTrpcClient = () => {
  const { getAccessToken } = useAuth();

  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: `${config.api.url}/trpc`,
          maxURLLength: 8192,
          async headers() {
            return {
              Authorization: `Bearer ${await getAccessToken()}`,
            };
          },
        }),
      ],
    }),
  );

  return trpcClient;
};
