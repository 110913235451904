import { FollowButton } from "@/components/button/FollowButton";
import { MessageButton } from "@/components/button/MessageButton";
import { useAuth } from "@/hooks/auth";
import { useGetSessionEventUsersBySessionEventId } from "@/hooks/session";
import { useGetUser } from "@/hooks/user";
import { ViewOrderButton } from "@/src/components/button/ViewOrderButton";
import { SessionEvent, SessionEventUser } from "@yoga-app/types";
import { Link } from "react-router-dom";
import Modal from "../../../../components/dialog/Modal";
import InfiniteList from "../../../../components/list/InfiniteList";
import ProfilePicture from "../../../../components/profile/ProfilePicture";

interface ParticipantsModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedSessionEvent?: SessionEvent;
}

const ParticipantsModal = ({ isOpen, onClose, selectedSessionEvent }: ParticipantsModalProps) => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetching } = useGetSessionEventUsersBySessionEventId(
    selectedSessionEvent?.id ?? "",
    selectedSessionEvent?.sessionOwnerUserId ?? "",
    {
      limit: 10,
    },
  );

  const items =
    data?.pages.flatMap((page) =>
      page.items.map((item) => ({
        id: item.userId,
        ...item,
        sessionOwnerUserId: selectedSessionEvent?.sessionOwnerUserId ?? "",
      })),
    ) || [];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Participants"
      className="h-full md:max-w-xl md:w-full md:max-h-[50vh]"
    >
      <div className="overflow-y-auto p-2 w-full h-full">
        {isLoading && (
          <div className="flex flex-col h-full justify-center items-center text-center">
            <span className="loading loading-spinner loading-sm text-secondary"></span>
          </div>
        )}

        {items.length > 0 ? (
          <div className="flex flex-col h-full w-full">
            <InfiniteList
              data={items}
              fetchNextPage={async () => {
                await fetchNextPage();
              }}
              hasNextPage={!!hasNextPage}
              isFetching={isFetching}
              CardComponent={ParticipantCard}
            />
          </div>
        ) : (
          <div className="flex flex-col h-full justify-center items-center text-center">
            <span className="text-textNeutral">You'll see participants here</span>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ParticipantsModal;

interface ParticipantCardProps {
  item: SessionEventUser & { sessionOwnerUserId: string };
}

const ParticipantCard = ({ item }: ParticipantCardProps) => {
  const { getUserId } = useAuth();
  const currentUserId = getUserId() as string;
  const { data: user } = useGetUser(item.userId);

  if (!user) return null;

  return (
    <div className="flex items-center justify-between p-3 bg-base-100 rounded-xl shadow">
      <Link to={`/${user.username}`}>
        <div className="flex items-center space-x-2">
          <ProfilePicture
            key={user.id}
            src={user.profilePic?.[150]}
            user={user}
            styles="w-14 h-14 cursor-pointer"
            avatarSize="text-sm"
          />
          <div className="flex flex-col">
            <span className="font-bold text-secondary overflow-ellipsis">{user.displayName ?? user.username}</span>
            <span className="text-sm text-gray-500">@{user.username}</span>
          </div>
        </div>
      </Link>

      <div className="flex items-center space-x-2">
        {/* TODO: Settings dropdown / icon / view order - only show for current user or session owner */}
        {user.id === currentUserId ||
          (currentUserId === item.sessionOwnerUserId && (
            <ViewOrderButton orderId={item.sessionEventId} userId={item.userId} className="w-12 h-12 max-h-10" />
          ))}

        {user.id !== currentUserId && (
          <FollowButton userId={currentUserId} followingUserId={user.id} className="w-12 h-12 max-h-10" />
        )}

        {user.id !== currentUserId && <MessageButton userId={user.id} className="w-12 h-12 max-h-10" />}
      </div>
    </div>
  );
};
