import { createContext, useContext } from "react";
import { ActiveOrderFeed } from "../types";

type ActiveOrderFeedContextType = {
  activeTab: ActiveOrderFeed;
  setActiveTab: (tab: ActiveOrderFeed) => void;
};

export const ActiveOrderFeedContext = createContext<ActiveOrderFeedContextType | undefined>(undefined);

export const useActiveOrderFeed = () => {
  const context = useContext(ActiveOrderFeedContext);
  if (!context) {
    throw new Error("useTab must be used within a TabProvider");
  }
  return context;
};
