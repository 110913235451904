import { useNavigate } from "react-router-dom";
import Icon from "../icon/Icon";

interface ViewOrderButtonProps {
  orderId: string;
  userId: string;
  className?: string;
  iconSize?: number;
}

export const ViewOrderButton = ({ orderId, userId, className, iconSize = 22 }: ViewOrderButtonProps) => {
  const navigate = useNavigate();

  return (
    <button
      className={`btn btn-circle outline-none border-0 bg-transparent text-base-100 ${className}`}
      onClick={() => navigate(`/order/${orderId}/${userId}`)}
    >
      <Icon name="orders" size={iconSize} color="#4b5563" />
    </button>
  );
};
