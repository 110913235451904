import Icon from "@/components/icon/Icon";

interface ParticipantCountBadgeProps {
  participantCount: number;
  maxParticipantCount?: number;
  className?: string;
  iconSize?: number;
  onClick?: () => void;
}

const ParticipantCountBadge = ({
  participantCount,
  maxParticipantCount,
  className = "",
  iconSize = 22,
  onClick = () => {},
}: ParticipantCountBadgeProps) => {
  return (
    <div className={`badge flex items-center p-2 ${className}`} onClick={onClick}>
      <Icon name="group" color="#32325d" size={iconSize} className="mr-1" />
      <span>
        {participantCount}
        {maxParticipantCount ? ` / ${maxParticipantCount}` : ""}
      </span>
    </div>
  );
};

export default ParticipantCountBadge;
