import { useMutation } from "@tanstack/react-query";
import { PageOptions } from "@yoga-app/db-utils";
import { Message } from "@yoga-app/types";
import config from "../config";
import { request } from "../utils/api";
import { trpc } from "../utils/trpc";
import { useAuth } from "./auth";

export const useGetOrCreateChat = () => {
  const utils = trpc.useUtils();
  return trpc.chat.getOrCreateChat.useMutation({
    onSuccess: async () => {
      await utils.chat.queryChats.invalidate();
    },
  });
};

export const useQueryChats = (pageOptions: PageOptions) => {
  return trpc.chat.queryChats.useInfiniteQuery(
    { limit: pageOptions.limit },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      staleTime: 10 * 60 * 1000, // 10 minutes
      cacheTime: 10 * 60 * 1000,
    },
  );
};

export const useQueryChatsWithUnreadMessages = (pageOptions: PageOptions) => {
  const { isAuthenticated } = useAuth();
  return trpc.chat.queryChatsWithUnreadMessages.useInfiniteQuery(
    { limit: pageOptions.limit },
    {
      enabled: isAuthenticated,
      refetchInterval: 600000, // 10 minutes
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      staleTime: 10 * 60 * 1000, // 10 minutes
      cacheTime: 10 * 60 * 1000,
    },
  );
};

export const useQueryMessages = (chatId: string, pageOptions: PageOptions) => {
  return trpc.chat.queryMessages.useInfiniteQuery(
    { chatId, limit: pageOptions.limit },
    {
      enabled: !!chatId,
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      refetchInterval: 30000, // 30 seconds
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
    },
  );
};

export const useSendMessage = () => {
  const { getAccessToken } = useAuth();
  const utils = trpc.useUtils();

  return useMutation(
    async (message: Message) => {
      const response = await request<{ messageId: string }>(`${config.api.url}/message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${await getAccessToken()}`,
        },
        body: JSON.stringify(message),
      });

      if (!response) {
        throw new Error("Failed to send message");
      }

      return response;
    },
    {
      onSuccess: (_, input) => {
        void utils.chat.queryMessages.invalidate({ chatId: input.chatId });
      },
    },
  );
};

export const useReadMessages = () => {
  const utils = trpc.useUtils();
  return trpc.chat.readMessages.useMutation({
    onSuccess: (_, variables) => {
      void utils.chat.queryChatsWithUnreadMessages.invalidate();
      void utils.chat.queryChats.invalidate({});
      void utils.chat.queryMessages.invalidate({ chatId: variables.chatId });
    },
  });
};
